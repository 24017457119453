(function() {
    var hamburger = {
      navToggle: document.querySelector('.nav-toggle'),
      nav: document.querySelector('nav'),
      doToggle: function(e) {
        e.preventDefault();
        this.navToggle.classList.toggle('expanded');
        this.nav.classList.toggle('expanded');
      }
    };
    hamburger.navToggle.addEventListener('click', function(e) { hamburger.doToggle(e); });
    //hamburger.nav.addEventListener('click', function(e) { hamburger.doToggle(e); });


      // SWIPER GALERIE :  presentations des différents arrets
      const swiper_arret = new Swiper('.swiper-arret', {
        // Optional parameters
        /*autoplay: {
          delay: 3000,
        },*/
        direction: 'horizontal',
        loop: true,
        slidesPerView: "auto",
        centeredSlides: true,
        spaceBetween: 0,
        // If we need pagination
        pagination: {
          el: '.swiper-pagination',
          clickable: true
        },
        // Navigation arrows
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
      });


    function initSwiper() {

      // SWIPER GALERIE 2
      const swiper_expo = new Swiper('.swiper-expo', {
        // Optional parameters
        autoplay: {
          delay: 3000,
        },
        direction: 'horizontal',
        loop: true,
        slidesPerView: "auto",
        centeredSlides: true,
        spaceBetween: 0,
        // If we need pagination
        pagination: {
          el: '.swiper-pagination',
          clickable: true
        },

        // Navigation arrows
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
      });

    }
    


  if (window.matchMedia("(min-width: 1024px)").matches) {
    /* La largeur minimum de l'affichage est 1024 px inclus */

  } else {
    /* L'affichage est inférieur à 1024px de large */
    initSwiper();
  }




// permet de fixer le menu et d'ajouter une classe visuelle au scroll de la page

function scrollFunction() {
  var element = document.querySelector("nav");
  if (document.body.scrollTop > 80 || document.documentElement.scrollTop > 80) {    
    element.classList.add("hop");
  } else {
    element.classList.remove("hop");
  }
}

// l'espion pour mettre à jour l'item de navigation actif

const spyScrolling = ( ) => {
  const sections = document.querySelectorAll( '.ancre' );

  window.onscroll = ( ) => {
    scrollFunction();
    const scrollPos = document.documentElement.scrollTop || document.body.scrollTop;

    for ( let s in sections )
      if ( sections.hasOwnProperty( s ) && sections[ s ].offsetTop <= (scrollPos+200) ) {
        const id = sections[ s ].id;
        document.querySelector( '.active' ).classList.remove( 'active' );
        document.querySelector( `a[href*=${ id }]` ).parentNode.classList.add( 'active' );
        //document.querySelector( `a[href*=${ id }]` ).classList.add( 'active' );
      }
  } 
}

spyScrolling( );



jQuery('.nav-link').on('click', function (e) {
  var targetSec = jQuery(this).attr('href');
  jQuery('html, body').animate({
      scrollTop: jQuery(targetSec).offset().top-150
  }, 1000);
});

jQuery('nav span').on('click', function (e) {
  var targetSec = jQuery(this).attr('data-href');
  jQuery('html, body').animate({
      scrollTop: jQuery(targetSec).offset().top-150
  }, 1000);
});


}());

